body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div {
  user-select: none;
}

@keyframes horizontalStatusBar {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #e2e2e2;
  box-shadow: 0 0 1px rgba(211, 112, 112, 0.5);
} */

::-webkit-scrollbar {
  /* Chrome/Opera/Safari */
  -webkit-appearance: none;
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  /* Chrome/Opera/Safari */
  border-radius: 3px;
  background-color: #e2e2e2;
  box-shadow: 0 0 1px rgba(211, 112, 112, 0.5);
}

input::-webkit-input-placeholder {
  color: #8E8E8E;
}

input:hover::-webkit-input-placeholder,
input:focus::-webkit-input-placeholder {
  color: #8E8E8E;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}

textarea::-webkit-input-placeholder {
  color: #8E8E8E;
}

textarea:hover::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: #8E8E8E;
}

.input-hover-style:hover {
  background-color: #f6f6f6 !important;
}

.toggle-item-style:hover {
  background-color: #f0f0f0 !important;
}

.toggle-selected-item-style:hover {
  background-color: #f6f6f6 !important;
}

.date-toggle-item-style:hover {
  background-color: #C4CFF8 !important;
  border-radius: 20px;
  
}

.date-toggle-selected-item-style:hover {
  background-color: #C4CFF8 !important;
  border-radius: 20px;
}

#filterScroll::-webkit-scrollbar {
  /* Chrome/Opera/Safari */
  -webkit-appearance: none;
  width: 0px;
  height: 0px;
}
.date-toggle-item-style-new:hover {
  background-color: #F5F5F5 !important;
  
}

.date-toggle-selected-item-style-new:hover {
  background-color: #EAEAEA !important;
}

.placeholder-green {
}

.placeholder-green::placeholder {
  color: #B7E1C3;
  opacity: 1; /* Firefox */
}
.placeholder-green:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #B7E1C3;
}
.placeholder-green::-ms-input-placeholder { /* Microsoft Edge */
 color: #B7E1C3;
}
#printable-area{
  position:absolute;
  left:-999px;
}
