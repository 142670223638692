/* .pulse {
  animation: pulse 3s infinite;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
} */

/* @keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
} */

/* .MayaPulse {
    animation: MayaPulse 3s infinite;
  }
  
  @-webkit-keyframes MayaPulse {
    0% {
      -webkit-transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.98);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  } */
  
  /* @keyframes MayaPulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.98);
    }
    100% {
      transform: scale(1);
    }
  } */
  
  .appointment-card{
    flex-shrink: 0;
    /* -webkit-flex: 0; */
  }